import {
    CreateButton,
    DatagridConfigurable,
    ExportButton,
    FunctionField,
    List,
    ReferenceField,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    useDataProvider,
    downloadCSV,
    fetchRelatedRecords,
    FetchRelatedRecords,
    ArrayInput,
    FilterButton,
    TextInput,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    SimpleFormIterator,
    SelectInput,
} from 'react-admin';

import groupYears from '../util/GroupYears';
import jsonExport from 'jsonexport/dist';
import { useEffect, useState } from 'react';

const fitmentFilters = [
    <SearchInput source="q" alwaysOn key="q" />,
    <ReferenceInput source="product_id" reference="products" label="Product" />,
    <TextInput source="make" label="Make" />,
    <TextInput source="model" label="Model" />,
    <TextInput source="submodel" label="Subbodel" />,
]

const exporter = async (fitment: any, fetchRelatedRecords: FetchRelatedRecords) => {
    const products = await fetchRelatedRecords(fitment, 'product_id', 'products');
    const fitmentForExport = fitment.map((f: any) => {
        const {product_id, submodel, engine, cab_type, bed_length, vehicle_option, notes, label, id, user_id, ...fitForExport} = f
        fitForExport.sku = products[product_id]?.sku;
        fitForExport.brand_id = products[product_id]?.brand_id;
        return fitForExport;
    });

    jsonExport(fitmentForExport, {
        headers: ['brand_id', 'sku', 'years', 'make', 'model', 'drive_type', 'fuel_type', 'num_doors']
    }, (err, csv) => {
        if (err) {
            console.error('Error exporting CSV:', err);
        } else {
            downloadCSV(csv, 'fitment');
        }
    });
}

function FitmentList() {
    return (
        <List
            filters={fitmentFilters}
            exporter={exporter}
            // sort={{ field: 'published_at', order: 'DESC' }}
            actions={
                <TopToolbar>
                    <CreateButton />
                    <FilterButton />
                    <SelectColumnsButton />
                    <ExportButton maxResults={100000}/>
                </TopToolbar>
            }
        >
            <DatagridConfigurable rowClick="edit">
                <ReferenceField label="Brand" source="product_id" reference="products" link={false} sortable={false}>
                    <ReferenceField source="brand_id" reference="brands">
                        <TextField source="brand_name" />
                    </ReferenceField>
                </ReferenceField>
                <ReferenceField source="product_id" reference="products">
                    <TextField source="title" />
                </ReferenceField>
                {/* <FunctionField label="Years" render={record => record.start_year === record.end_year ? `${record.start_year}` : `${record.start_year} - ${record.end_year}`} /> */}
                <FunctionField label="Years" render={record => groupYears(record.years)} />
                {/* <TextField source="years" /> */}
                <TextField source="make" />
                <TextField source="model" />
                <TextField source="submodel" />
                <TextField source="engine" />
                <TextField source="cab_type" />
                <TextField source="bed_length" />
                <TextField source="num_doors" />
                <TextField source="drive_type" />
                <TextField source="fuel_type" />
                <TextField source="vehicle_options" />
                <TextField source="notes" />
                <TextField source="label" />
            </DatagridConfigurable>
        </List>
    );
}

export default FitmentList;
