import { AuthProvider } from 'react-admin';

interface LoginParams {
  username: string;
  password: string;
}

interface Auth {
  id: number;
  token: string;
  username: string;
  developer?: string;
}

function getUrl(envVar: string): URL {
  if (!envVar) {
      throw new Error(`Auth URL is not set, check .env file`);
  }
  return new URL(envVar);
}

export const authProvider: AuthProvider = {
  login: ({ username, password }: LoginParams) => {
    let url: URL;
    if (import.meta.env.DEV) {
      url = getUrl(import.meta.env.VITE_AUTH_URL_DEV);
    } else {
      url = getUrl(import.meta.env.VITE_AUTH_URL_PROD);
    }
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth: Auth) => {
        auth.developer ? sessionStorage.setItem('auth', JSON.stringify({ fullName: username, key: auth.token, id: auth.id, developer: auth.developer }))
        : sessionStorage.setItem('auth', JSON.stringify({ fullName: username, key: auth.token, id: auth.id }));
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      sessionStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    sessionStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject({ message: 'login required' }),
  logout: () => {
    sessionStorage.removeItem('auth');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { fullName, key, id } = JSON.parse(sessionStorage.getItem('auth') || '{}');
      return Promise.resolve({ fullName, key, id });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: (params: any) => Promise.resolve(),
  // canAccess: ({resource}) => {
  //   const { developer } = JSON.parse(sessionStorage.getItem('auth') || '{}');
  //   return resource === 'dev' ?
  //     developer ? Promise.resolve(true) : Promise.reject(false)
  //     : Promise.resolve(true);
  // }
};

export default authProvider;