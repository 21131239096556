import {Edit} from 'react-admin';
import { UsersForm } from './Form';

export default function UsersEdit() {
    return (
        <Edit>
            <UsersForm />
        </Edit>
    );
}

