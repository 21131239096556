import { ArrayInput, FormDataConsumer, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput, required } from 'react-admin';
import { Grid } from '@mui/material';

const paths = [
    { id: "make", name: "Make" },
    { id: "model", name: "Model" },
    { id: "submodel", name: "Submodel" },
    { id: "engine", name: "Engine" },
    { id: "cab_type", name: "Cab Type" },
    { id: "bed_length", name: "Bed Length (in)" },
    { id: "num_doors", name: "Number of Doors" },
    // { id: "years", name: "Years" }
]

const actionPaths = [
    { id: "make", name: "Make" },
    { id: "model", name: "Model" },
    { id: "submodel", name: "Submodel" },
    { id: "engine", name: "Engine" },
    { id: "cab_type", name: "Cab Type" },
    { id: "bed_length", name: "Bed Length (in)" },
    { id: "num_doors", name: "Number of Doors" },
    // { id: "years", name: "Years" }
]

// const subpaths: Record<string, string[]> = {
//     // brand_id: should be a relationship input
//     pricing: ['price_type', 'price'],
//     shipping: ['wieght', 'height', 'width', 'length', 'dimension_unit', 'weight_unit'],
//     images: ['url'],
//     media: ['url'],
// };

function capitalize(str: string) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
    }
    return frags.join(' ');
  }
  

const toChoices = (items: string[]) => items.map((item) => ({ id: item, name: capitalize(item) }));

const conditionCompare = ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN", "GREATER_THAN_OR_EQUAL", "LESS_THAN_OR_EQUAL", "CONTAINS", "NOT_CONTAINS", "CONTAINED_IN", "NOT_CONTAINED_IN", "STARTS_WITH", "ENDS_WITH", "REGEX", "NOT_REGEX", "IN_RANGE"]

// const brandCompare = ["EQUALS", "NOT_EQUALS"]


export const FitmentRuleForm = () => {
    return (
    <SimpleForm>
        <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={8}>
                <TextInput source="name" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SelectInput label="Condition Logic" source="rule_logic" defaultValue={"ALL"} choices={toChoices(["ANY", "ALL"])} validate={[required()]} fullWidth />
            </Grid>
        </Grid>
        <ArrayInput source="conditions">
            <SimpleFormIterator inline>
                <SelectInput source="path" choices={paths} validate={[required()]} />
                {/* <FormDataConsumer<{ path: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && (scopedFormData.path in subpaths) && <SelectInput source={"subpath"} choices={scopedFormData.path ? toChoices(subpaths[scopedFormData.path]) : []} />}
                </FormDataConsumer> */}
                <FormDataConsumer<{ path: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && (scopedFormData.path === "custom_fields") && <TextInput source={"subpath"} label="Custom Field Name" />}
                </FormDataConsumer>
                <SelectInput source='operator' choices={toChoices(conditionCompare)} />
                <FormDataConsumer<{ path: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && (scopedFormData.path !== "brand_id" ) && <TextInput source={"value"} />}
                </FormDataConsumer>
                <FormDataConsumer<{ path: string, operator: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && (scopedFormData.path === "brand_id" ) && (brandCompare.includes(scopedFormData.operator)) && <ReferenceInput source={"value"} reference="brands" ><SelectInput parse={value => value.toString()} /></ReferenceInput>}
                </FormDataConsumer>
                <FormDataConsumer<{ path: string, operator: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && (scopedFormData.path === "brand_id" ) && !(brandCompare.includes(scopedFormData.operator)) && <TextInput source={"value"} />}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="actions">
            <SimpleFormIterator inline>
                <FormDataConsumer<{ action: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && scopedFormData.action !== 'EXCLUDE' && <SelectInput source={"path"} choices={actionPaths} validate={[required()]} />}
                </FormDataConsumer>
                {/*                 
                <FormDataConsumer<{ path: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && (scopedFormData.path in subpaths) && <SelectInput source={"subpath"} choices={scopedFormData.path ? toChoices(subpaths[scopedFormData.path]) : []} />}
                </FormDataConsumer> */}
                <SelectInput source="action" choices={toChoices(["SET", "REMOVE", "EXCLUDE", "REPLACE"])} />
                <FormDataConsumer<{ action: string }>>
                    {({ formData, scopedFormData, ...rest }) => scopedFormData && scopedFormData.action !== 'EXCLUDE' && <TextInput source={"value"} />}
                </FormDataConsumer>
            </SimpleFormIterator>
    </ArrayInput>
    </SimpleForm>
    );
}