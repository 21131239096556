// in ./CustomResetViewsButton.tsx
import {
    useListContext,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    useDataProvider,
    Button,
} from 'react-admin';
import { useMutation } from "@tanstack/react-query";

// https://marmelab.com/react-admin/Datagrid.html#bulkactionbuttons
// https://marmelab.com/react-admin/Actions.html#calling-custom-methods

export const ApplyBulkFitmentRulesButton = () => {
    const { selectedIds } = useListContext();
    const dataProvider = useDataProvider();
    const mutation = useMutation({
        mutationFn: () => dataProvider.applyBulkFitmentRules(selectedIds)
});

    return (
        <Button label="Apply Rules" onClick={() => mutation.mutate()} disabled={mutation.isPending}/>
    );
};