import {
    Create,
} from 'react-admin';
import { UsersForm } from './Form';

function UsersCreate() {
    return (
        <Create>
            <UsersForm />
        </Create>
    );
}

export default UsersCreate;
