import {
    ArrayField,
    ArrayInput,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DateInput,
    Edit,
    ImageField,
    Labeled,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyCount,
    ReferenceManyField,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    SimpleList,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Card, CardMedia, Stack, InputAdornment } from '@mui/material';
import { ProductEditContent } from './Form';

function NProductCreate() {
    return (
        <Create>
            <SimpleForm>
            <ProductEditContent />
            </SimpleForm>
        </Create>
    );
}

export default NProductCreate;
