import { Edit } from 'react-admin';
import { FitmentRuleForm } from './form';

function FitmentRuleEdit() {
    return (
        <Edit>
            <FitmentRuleForm />
        </Edit>
    );
}

export default FitmentRuleEdit;
