import { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import { Datagrid, List, useDataProvider, TextField, BooleanField } from 'react-admin';
import LogsPanel from './logs';
import CeleryLogsPanel from './celeryLogs';

const DeveloperTab = () => {
    const dataProvider = useDataProvider();
    const [celeryStatus, setCeleryStatus] = useState('');

    useEffect(() => {
        dataProvider.getCeleryStatus()
          .then(data => setCeleryStatus(data.status))
          .catch(error => {
            console.error('Failed to get Celery status:', error);
          });
      }, []);

    return (
        <Card
        sx={{
            padding: '20px',
            marginTop: 2,
            marginBottom: '1em',
        }}
        >
            <div>
                <h2>Developer</h2>
                <p>Celery Status: {celeryStatus}</p>
            </div>
              <LogsPanel />
              <CeleryLogsPanel />
              <div>
                <h2>Users</h2>
                <List resource='users'>
                  <Datagrid  rowClick="edit">
                      <TextField source="id" />
                      <TextField source="username" />
                      <BooleanField source="developer" />
                  </Datagrid>
                </List>
              </div>
        </Card>
    );
};

export default DeveloperTab;
