import {
    BooleanField,
    CreateButton,
    DatagridConfigurable,
    List,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';

function UsersList() {
    return (
        <List
            // sort={{ field: 'published_at', order: 'DESC' }}
            filters={[<SearchInput source="q" alwaysOn key="q" />]}
            actions={
                <TopToolbar>
                    <CreateButton />
                </TopToolbar>
            }
        >
            <DatagridConfigurable rowClick="edit">
                <TextField source="username" />
                <BooleanField source="developer" />
            </DatagridConfigurable>
        </List>
    );
}

export default UsersList;
