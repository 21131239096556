import {
    Admin,
    Resource,
    radiantLightTheme,
    radiantDarkTheme,
    ListGuesser,
    CustomRoutes,
    Button
} from 'react-admin';

import { Route } from 'react-router-dom';

import dataProvider from './dataProvider';
import { QTools } from './QTools';
import authProvider from './authProvider';
import nproducts from './products';
import nbrands from './brands';
import Dashboard from './dashboard/dashboard';
import DeveloperTab from './dashboard/dev';
import sema from './sema';
import tokens from './tokens';
import nrules from './rules';
import fitments from './fitment';
import bigcommerce from './bigcommerce';
import mapping from './Mapping';
import updates from './updates';
import fitmentUpdates from './fitmentUpdates';
import Settings from './Settings/settings';
import fitmentRules from './fitmentRules';
import users from './users';

function App() {
    return (
            <Admin
                disableTelemetry
                dashboard={Dashboard}
                dataProvider={dataProvider}
                authProvider={authProvider}
                lightTheme={radiantLightTheme}
                darkTheme={radiantDarkTheme}
                layout={QTools}
                title="SearchAUTO PIM">
                <CustomRoutes>
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/dev" element={<DeveloperTab />} />
                </CustomRoutes>
                
                <Resource name = "products" options={{ label:"Products" }} {...nproducts} />              
                <Resource name = "brands" options={{ label:"Brands" }} {...nbrands} /> 
                <Resource name = "fitments" options={{ label:"Fitment" }} {...fitments} />
                <Resource name = "rules" options={{ label:"Rules" }} {...nrules} />
                <Resource name = "sema-connection" options={{ label:"SEMA" }} {...sema} />
                <Resource name = "bigcommerce-connection" options={{ label:"BigCommerce" }} {...bigcommerce} />
                <Resource name = "mappings" options={{ label:"Mappings" }} {...mapping} />
                <Resource name = "api-tokens" options={{ label:"API Tokens" }} {...tokens} />
                <Resource name = "tags" recordRepresentation="name" />
                <Resource name = "updates" options={{ label: "Updates" }} {...updates} />
                <Resource name = "fitment-updates" options={{ label: "Fitment Updates" }} {...fitmentUpdates} />
                <Resource name = "fitment-rules" options={{ label: "Fitment Rules" }} {...fitmentRules} />
                <Resource name = "variant-options" options={{ label: "Variant Options" }} />
                <Resource name = "options" options={{ label: "Options" }} />
                <Resource name = "variants" options={{ label: "Variants" }} />
                <Resource name = "users" options={{ label: "Users" }} {...users} />
            </Admin>
    );
}

export default App;
