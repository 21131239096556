import { Create } from 'react-admin';
import { FitmentRuleForm } from './form';

function FitmentRuleCreate() {
    return (
        <Create>
            <FitmentRuleForm />
        </Create>
    );
}

export default FitmentRuleCreate;
