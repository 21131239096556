import { useState, useEffect } from 'react';
import { Button, useDataProvider } from 'react-admin';

const LogsPanel = () => {
    const dataProvider = useDataProvider();
    const [logs, setLogs] = useState('');

    useEffect(() => {
        dataProvider.getLogs()
            .then(data => setLogs(data))
            .catch(() => setLogs('Error fetching logs.'));
    }, []);

    const handle_clear_logs = () => {
        dataProvider.clearLogs()
            .then(() => setLogs(''))
            .catch(() => setLogs('Error clearing logs.'));
    };

    const handle_refresh_logs = () => {
        dataProvider.getLogs()
            .then(data => setLogs(data))
            .catch(() => setLogs('Error fetching logs.'));
    };

    return (
        <div>
            <h2>Logs</h2>
            <Button onClick={handle_refresh_logs} label="Refresh" />
            <Button onClick={handle_clear_logs} label="Clear" />
            <pre style={{ whiteSpace: 'pre-wrap', overflowY: 'scroll', maxHeight: '400px', flexDirection: 'column-reverse', display: 'flex' }}>
                {logs}
            </pre>
        </div>
    );
};

export default LogsPanel;
