import {
    SimpleForm,
    Labeled,
    ReferenceField,
    required,
    TextInput,
    TextField,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
} from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text'


const FitmentForm = () => (
    <SimpleForm>
        <Labeled label="Product">
            <ReferenceField source="product_id" reference="products">
                <TextField source="sku" />
            </ReferenceField>
        </Labeled>
        <TextField source="label" />
        <TextInput source="make" validate={required()} />
        <TextInput source="model" validate={required()} />
        <TextInput source="submodel" />
        <TextInput source="engine" />
        <TextInput source="cab_type" />
        <NumberInput source="bed_length" label="Bed Length (in)" />
        <NumberInput source="num_doors" />
        <RichTextInput source="notes" />
        <ArrayInput source="years" >
            <SimpleFormIterator>
                <NumberInput />
            </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
);

export default FitmentForm;