import * as React from 'react';
import {
    ArrayInput,
    AutocompleteArrayInput,
    Button,
    Create,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    SimpleList,
    TextInput,
    useCreate,
    useRecordContext,
    ImageField,
    useInput,
    NumberInput,
    ChipField,
    SingleFieldList,
    useReferenceManyFieldController,
    SaveButton,
    Toolbar,
    useCreateSuggestionContext,
    Form,
    useNotify,
    CreateButton,
    TextArrayInput,
    TextField,
    AutocompleteInput,
    useFormContext,
    BooleanInput,
    FormDataConsumer,
    DateInput,
} from 'react-admin';
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, Box, Typography, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, CardMedia, CardActions, IconButton, InputAdornment, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RichTextInput } from 'ra-input-rich-text';
import groupYears from '../util/GroupYears';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Fragment, Component } from 'react';
import dataProvider from '../dataProvider';
import { Domain } from 'domain';
import { useCallback } from 'react';
import { stringify } from 'querystring';

const priceChoices = [
    { id: 'AC1', name: 'AC1' },
    { id: 'AC2', name: 'AC2' },
    { id: 'TRD', name: 'TRD' },
    { id: 'USR', name: 'USR' },
    { id: 'UNL', name: 'UNL' },
    { id: 'INP', name: 'INP' },
    { id: 'INN', name: 'INN' },
    { id: 'INM', name: 'INM' },
    { id: 'FLT', name: 'FLT' },
    { id: 'DLV', name: 'DLV' },
    { id: 'DLR', name: 'DLR' },
    { id: 'DLN', name: 'DLN' },
    { id: 'CRW', name: 'CRW' },
    { id: 'CRJ', name: 'CRJ' },
    { id: 'CRG', name: 'CRG' },
    { id: 'CR4', name: 'CR4' },
    { id: 'CR3', name: 'CR3' },
    { id: 'CR2', name: 'CR2' },
    { id: 'CR1', name: 'CR1' },
    { id: 'ZZ9', name: 'ZZ9' },
    { id: 'ZZ8', name: 'ZZ8' },
    { id: 'ZZ7', name: 'ZZ7' },
    { id: 'ZZ6', name: 'ZZ6' },
    { id: 'ZZ5', name: 'ZZ5' },
    { id: 'ZZ4', name: 'ZZ4' },
    { id: 'ZZ3', name: 'ZZ3' },
    { id: 'ZZ2', name: 'ZZ2' },
    { id: 'ZZ1', name: 'ZZ1' },
    { id: 'WVP', name: 'WVP' },
    { id: 'WMP', name: 'WMP' },
    { id: 'WLS', name: 'WLS' },
    { id: 'WDP', name: 'WDP' },
    { id: 'WD1', name: 'WD1' },
    { id: 'JBN', name: 'JBN' },
    { id: 'MEC', name: 'MEC' },
    { id: 'LST', name: 'LST' },
    { id: 'JBR', name: 'JBR' },
    { id: 'JBC', name: 'JBC' },
    { id: 'RMP', name: 'RMP' },
    { id: 'QPB', name: 'QPB' },
    { id: 'PKP', name: 'PKP' },
    { id: 'PRO', name: 'PRO' },
    { id: 'QOT', name: 'QOT' },
    { id: 'RAC', name: 'RAC' },
    { id: 'RET', name: 'RET' },
    { id: 'INS', name: 'INS' },

]

const ImageInputWithPreview = ({ source, record, ...rest }) => {
    const { field } = useInput({ source, ...rest });
    const imageUrl = field.value || '';

    return (
        <Card variant="outlined" sx={{ position: 'relative', marginBottom: 2 }}>
            {imageUrl ? (
                <CardMedia
                    component="img"
                    image={imageUrl}
                    title="Uploaded Image"
                    sx={{
                        // Adjust height for larger preview
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        No Image
                    </Typography>
                </CardContent>
            )}
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <TextInput
                    source={source}
                    placeholder="Image URL"
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ mt: 1 }}
                />
            </CardActions>
        </Card>
    );
};

// const CreateVariantOption = () => {
//     const { filter, onCancel, onCreate } = useCreateSuggestionContext();
//     const [create] = useCreate();
//     const [value, setValue] = React.useState(filter || '');

//     const handleCreate = event => {
//         event.preventDefault();
//         create(
//             'options',
//             { data: { name: value } },
//             {
//                 onSuccess: ({ data }) => {
//                     onCreate(data);
//                 },
//             }
//         );
//     };
//     return (
//         <Dialog open onClose={onCancel}>
//             <form onSubmit={handleCreate}>
//                 <DialogTitle>Create a new option</DialogTitle>
//                 <DialogContent>
//                     <TextField label="New Option" value={value} onChange={e => setValue(e.target.value)} autoFocus />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button label="Cancel" onClick={onCancel} />
//                     <Button label="Create" type="submit" />
//                 </DialogActions>
//             </form>
//         </Dialog>
//     )
// }


// const FitmentCreateButton = () => {
//     const product = useRecordContext();
//     return (<CreateButton resource="fitments" state={{ record: { product_id: product.id } }} />);
// };


const BrandInput = (props: any) => {
    const [create] = useCreate();
    return (
        <ReferenceInput {...props} reference="brands" sort={{ field: "brand_name", order: "ASC" }} perPage={100}>
            <SelectInput optionText="brand_name" validate={req} fullWidth onCreate={async () => {
                const newBrandName = prompt('Enter the new brand name');
                const newBrandId = prompt('Enter the new brand ID');
                if (newBrandName && newBrandId) {
                    const newBrand = await create('brands', { data: { brand_name: newBrandName, aaia_id: newBrandId } });
                    return newBrand.data.id
                }
            }} />
        </ReferenceInput>
    );
}

// const optionRenderer = choice => {
//     const { form } = useFormContext();
//     return `${choice.name}`;
// }

const TagInput = (props: any) => {
    const [create] = useCreate();
    return (
        <ReferenceArrayInput {...props} reference="tags" source="tags">
            <AutocompleteArrayInput optionText="name" fullWidth onCreate={async () => {
                const tagName = prompt('New Tag Name');
                if (tagName) {
                    const tag = await create('tags', { data: { name: tagName } });
                    return tag.data.id
                }
            }} />
        </ReferenceArrayInput>
    );
}

// const 

export const ProductEditContent = () => {
    return (
    <Grid container columnSpacing={2}>
        <Grid item xs={12} md={8} sm={8}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={8}>
                    <TextInput source="title" fullWidth validate={req} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="sku" label="SKU" validate={req} fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <BrandInput source="brand_id" label="Brand" fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput
                        source="part_type"
                        fullWidth
                        validate={req}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TagInput />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextInput source="short_description" validate={req} fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <RichTextInput source="long_description" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h5">Images</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ArrayInput source="images" label={false}>
                                <SimpleFormIterator>
                                    <ImageInputWithPreview source="url" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </AccordionDetails>
                    </Accordion>
                <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Variant Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                            <ArrayInput source="variant_options" label={false}>
                                <SimpleFormIterator inline>
                                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> */}
                                        <TextInput source="name" />
                                        <SelectInput source="type" choices={[{id: "dropdown", name: "Dropdown"},{id: "swatch", name: "Swatch"},{id: "radio_buttons", name: "Radio Buttons"},{id: "rectangle_list", name: "Rectangle List"},]} />
                                    {/* </Box> */}
                                    <ArrayInput source="options">
                                        <SimpleFormIterator inline>
                                            <TextInput source="name" />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Variants</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                            <ArrayInput source="variants" label={false}>
                                <SimpleFormIterator>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <ReferenceArrayInput source="options" reference="options" label="Options">
                                            <AutocompleteArrayInput />
                                        </ReferenceArrayInput>
                                        <TextInput source="name" />
                                        <TextInput source="sku" label="SKU" />
                                        <NumberInput source="price" />
                                    </Box>
                                    <ImageInputWithPreview source="image_url" />
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <NumberInput source="weight" />
                                        <NumberInput source="length" />
                                        <NumberInput source="width" />
                                        <NumberInput source="height" />
                                    </Box>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Modifiers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ overflowY: 'auto', overflowX: 'scroll' }}>
                            <ArrayInput source="modifiers" label={false}>
                                <SimpleFormIterator inline>
                                    <SelectInput source="type" choices={[
                                        { id: 'date', name: 'Date' },
                                        { id: 'checkbox', name: 'Checkbox' },
                                        { id: 'file', name: 'File' },
                                        { id: 'text', name: 'Text' },
                                        { id: 'multi_line_text', name: 'Multi Line Text' },
                                        { id: 'numbers_only_text', name: 'Numbers Only Text' },
                                        { id: 'radio_buttons', name: 'Radio Buttons' },
                                        { id: 'rectangles', name: 'Rectangles' },
                                        { id: 'dropdown', name: 'Dropdown' },
                                        { id: 'product_list', name: 'Product List' },
                                        { id: 'product_list_with_images', name: 'Product List With Images' },
                                        { id: 'swatch', name: 'Swatch' },
                                    ]} />
                                    <BooleanInput source="required" />
                                    <FormDataConsumer>
                                        {({ formData, scopedFormData }) => scopedFormData?.type === "checkbox" ? 
                                        <>
                                            <BooleanInput source="config.checked_by_default" label="Checked by Default" />
                                            <TextInput source="config.checkbox_label" label="Checkbox Label"/>
                                        </>
                                        : scopedFormData?.type === "text" ?
                                        <>
                                            <TextInput source="config.default_value" label="Default Value" />
                                            <BooleanInput source="config.text_characters_limited" label="Limit Characters" />
                                            <FormDataConsumer>
                                                {({ scopedFormData }) => scopedFormData?.config?.text_characters_limited ? <><NumberInput source="config.text_min_length" label="Minimum" /><NumberInput source="config.text_max_length" label="Maximum"/></> : <></>}
                                            </FormDataConsumer>
                                        </>
                                        : scopedFormData?.type === "numbers_only_text" ?
                                        <>
                                            <NumberInput source='config.default_value' label="Default Value" />
                                            <BooleanInput source="config.number_integers_only" label="Integers Only" />
                                            <BooleanInput source="config.number_limited" label="Limit Numbers" />
                                            <FormDataConsumer>
                                                {({ scopedFormData }) => scopedFormData?.config?.number_limited ? <><NumberInput source="config.number_lowest_value" label="Minimum" /><NumberInput source="config.number_highest_value" label="Maximum"/></> : <></>}
                                            </FormDataConsumer>
                                        </>
                                        : scopedFormData?.type === "radio_buttons" ?
                                        <>
                                            <ArrayInput source="option_values">
                                                <SimpleFormIterator inline>
                                                    <TextInput source="label" />
                                                    <BooleanInput source="is_default" />
                                                </SimpleFormIterator>
                                            </ArrayInput>
                                        </> 
                                        : scopedFormData?.type === "dropdown" ?
                                        <>
                                            <ArrayInput source="option_values">
                                                <SimpleFormIterator>
                                                    <TextInput source="label" />
                                                    <BooleanInput source="is_default" />
                                                </SimpleFormIterator>
                                            </ArrayInput>
                                        </>
                                        : scopedFormData?.type === "rectangles" ?
                                        <>
                                            <ArrayInput source="option_values">
                                                <SimpleFormIterator inline>
                                                    <TextInput source="label" />
                                                    <BooleanInput source="is_default" />
                                                </SimpleFormIterator>
                                            </ArrayInput>
                                        </>
                                        : scopedFormData?.type === "file" ?
                                        <>
                                            <SelectInput source="config.file_types_mode" choices={[{id: 'specific', name: 'Specific'}, {id: 'all', name: 'All'}]} />
                                            <TextArrayInput source="config.file_types_supported" />
                                            <BooleanInput source="config.file_types_other" label="Allow Other File Types" />
                                            <NumberInput source="config.max_file_size" label="Max File Size" />
                                        </>
                                        : scopedFormData?.type === "product_list" || scopedFormData?.type === "product_list_with_images" || scopedFormData?.type === "swatch" ?
                                        <>
                                            ****Can't do this yet
                                        </>
                                        : scopedFormData?.type === "date" ?
                                        <>
                                            <BooleanInput source="config.date_limited" label="Limit Date" />
                                            <DateInput source="config.default_value" label="Default Value" />
                                            <FormDataConsumer>
                                                {({ scopedFormData }) => scopedFormData?.config?.date_limited ? 
                                                <>
                                                    <SelectInput source="config.date_limit_mode" choices={[{id: 'range', name: 'Range'}, {id: 'earliest', name: 'Earliest'}, {id: 'latest', name: 'Latest'}]} />

                                                    <FormDataConsumer>
                                                        {({ scopedFormData }) => scopedFormData?.config?.date_limit_mode === "range" ?
                                                        <>
                                                            <DateInput source="config.date_earliest_value" label="Earliest" />
                                                            <DateInput source="config.date_latest_value" label="Latest" />
                                                        </>
                                                        : scopedFormData?.config?.date_limit_mode === "earliest" ?
                                                        <>
                                                            <DateInput source="config.date_earliest_value" />
                                                        </>
                                                        : scopedFormData?.config?.date_limit_mode === "latest" ?
                                                        <>
                                                            <DateInput source="config.date_latest_value" />
                                                        </>
                                                        : <></>}
                                                    </FormDataConsumer>
                                                </>
                                                : <></>}
                                            </FormDataConsumer>
                                        </>
                                        : scopedFormData?.type === "multi_line_text" ?
                                        <>
                                            <BooleanInput source="config.text_lines_limited" label="I want to control the number of lines entered" />
                                            <BooleanInput source="config.text_characters_limited" label="I want to control the number of characters entered" />
                                            <FormDataConsumer>
                                                {({ scopedFormData }) => scopedFormData?.config?.text_lines_limited && <><NumberInput source="config.text_max_lines" label="Maximum Lines" /><NumberInput source="config.text_min_lines" label="Minimum Lines" /></>}
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {({ scopedFormData }) => scopedFormData?.config?.text_characters_limited && <><NumberInput source="config.text_max_length" label="Maximum Characters" /><NumberInput source="config.text_min_length" label="Minimum Characters" /></>}
                                            </FormDataConsumer>
                                        </>
                                        : <></>}

                                    </FormDataConsumer>
                                    {/* <NumberInput source="sort_order" defaultValue={0} /> */}
                                    {/* <ArrayInput source="option_values">
                                        <SimpleFormIterator>
                                            <TextInput source="label" />
                                            <BooleanInput source="is_default" /> */}

                                            {/* <NumberInput source="sort_order" defaultValue={0} /> */}
                                            {/* <ArrayInput source="adjusters">
                                                <SimpleFormIterator>
                                                    <ArrayInput source="price">
                                                        <SimpleFormIterator>
                                                            <SelectInput source="adjuster" choices={[{id: 'relative', name: 'Relative'}, {id: 'percentage', name: 'Percentage'}]} />
                                                            <NumberInput source="adjuster_value" />
                                                        </SimpleFormIterator>
                                                    </ArrayInput>
                                                    <ArrayInput source="weight">
                                                        <SimpleFormIterator>
                                                            <SelectInput source="adjuster" choices={[{id: 'relative', name: 'Relative'}, {id: 'percentage', name: 'Percentage'}]} />
                                                            <NumberInput source="adjuster_value" />
                                                        </SimpleFormIterator>
                                                    </ArrayInput>
                                                </SimpleFormIterator>
                                            </ArrayInput> */}
                                        {/* </SimpleFormIterator> */}
                                    {/* </ArrayInput> */}
                                    {/* <ReferenceArrayInput source="options" reference="options" label="Options">
                                        <AutocompleteArrayInput />
                                    </ReferenceArrayInput>
                                    <TextInput source="name" />
                                    <NumberInput source="price" />
                                    <TextInput source="sku" label="SKU" />
                                    <ImageInputWithPreview source="image_url" />
                                    <NumberInput source="weight" />
                                    <NumberInput source="length" />
                                    <NumberInput source="width" />
                                    <NumberInput source="height" /> */}
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Additional Fields</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                            <RichTextInput source="features_and_benefits" fullWidth />
                            <RichTextInput source="extended_description" fullWidth />
                            <TextInput source="invoice_description" fullWidth />
                            <TextInput source="asc_description" fullWidth />
                            <TextInput source="emissions_code" />
                            <TextInput source="life_cycle_status" />
                            <TextInput source="life_cycle_status_description" />
                            <TextInput source="country_of_origin" />
                            <TextInput source="remanufactured_part" />
                            <TextInput source="product_condition" />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={4} sm={4}>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Fitments</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ReferenceManyField reference="fitments" target="product_id" source='id' label={false} >
                            <SimpleList
                                primaryText={record => record.make + ' ' + record.model + ' ' + (record.submodel || "")}
                                secondaryText={record => groupYears(record.years)}
                                tertiaryText={record => <ReferenceManyField reference="fitment-updates" target="fitment_id" source="id"><SimpleList primaryText={record => record.value} secondaryText={record => record.label} empty={<></>} /></ReferenceManyField>}
                            />
                        </ReferenceManyField>
                    </Box>
                    {/* <Button
                        label="Add Fitment"
                        onClick={() => {
                            alert('Add Fitment');
                        }}
                        fullWidth
                    /> */}
                    {/* <FitmentCreateButton /> */}
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Custom Fields</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ArrayInput source="custom_fields" label={false}>
                            <SimpleFormIterator inline>
                                <TextInput source="name" />
                                <TextInput source="value" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Shipping</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ArrayInput source="shipping" label={false}>
                            <SimpleFormIterator inline>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="length" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="width" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="height" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectInput source="dimension_unit" choices={[
                                            { id: 'IN', name: 'Inches' },
                                            { id: 'CM', name: 'Centimeters' }
                                        ]} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="weight" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectInput source="weight_unit" choices={[
                                            { id: 'LB', name: 'Pounds' },
                                            { id: 'KG', name: 'Kilograms' }
                                        ]} />
                                    </Grid>
                                </Grid>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Price</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ArrayInput source="pricing" label={false}>
                            <SimpleFormIterator inline>
                                <AutocompleteInput source="price_type" choices={priceChoices} />
                                <NumberInput source="price" InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>) }} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>

                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Categories</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                        <ArrayInput source="categories" label={false}>
                            <SimpleFormIterator>
                                <TextInput source="name" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </AccordionDetails>
            </Accordion>


        </Grid>
    </Grid>
    );
};

const req = [required()];

