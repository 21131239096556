import { useState, useEffect } from 'react';
import { Button, useDataProvider } from 'react-admin';

const CeleryLogsPanel = () => {
    const dataProvider = useDataProvider();
    const [celeryLogs, setCeleryLogs] = useState('');

    useEffect(() => {
        dataProvider.getCeleryLogs()
            .then(data => setCeleryLogs(data))
            .catch(() => setLogs('Error fetching logs.'));
    }, []);

    const handle_clear_celery_logs = () => {
        dataProvider.clearCeleryLogs()
            .then(() => setCeleryLogs(''))
            .catch(() => setCeleryLogs('Error clearing Celery logs.'));
    };

    const handle_refresh_celery_logs = () => {
        dataProvider.getCeleryLogs()
            .then(data => setCeleryLogs(data))
            .catch(() => setCeleryLogs('Error fetching Celery logs.'));
    };

    return (
        <div>
            <h2>Celery Logs</h2>
            <Button onClick={handle_refresh_celery_logs} label="Refresh" />
            <Button onClick={handle_clear_celery_logs} label="Clear" />
            <pre style={{ whiteSpace: 'pre-wrap', overflowY: 'scroll', maxHeight: '400px', flexDirection: 'column-reverse', display: 'flex' }}>
                {celeryLogs}
            </pre>
        </div>
    );
};

export default CeleryLogsPanel;
