import {
    ArrayField,
    BooleanField,
    ChipField,
    CreateButton,
    DatagridConfigurable,
    DateField,
    ExportButton,
    FilterButton,
    ImageField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceArrayField,
    SearchInput,
    SelectColumnsButton,
    SingleFieldList,
    TextField,
    TopToolbar,
    Button,
    FunctionField,
} from 'react-admin';
import { Fragment } from 'react/jsx-runtime';
import { ApplyBulkFitmentRulesButton } from './applyBulkFitmentRulesButton';

const RulesBulkActionButtons = (props: any) => (
    <Fragment>
        <ApplyBulkFitmentRulesButton {...props} />
    </Fragment>
);

function FitmentRuleList() {
    return (
        <List
            // sort={{ field: 'published_at', order: 'DESC' }}
            filters={[<SearchInput source="q" alwaysOn key="q" />]}
            actions={
                <TopToolbar>
                    <FilterButton />
                    <SelectColumnsButton />
                    <CreateButton />
                    <ExportButton />
                </TopToolbar>
            }
        >
            <DatagridConfigurable bulkActionButtons={<RulesBulkActionButtons />} rowClick="edit">
                <TextField source="name" />
                <ArrayField source="conditions" perPage={3} sortable={false} >
                    <SingleFieldList>
                        <ChipField source='path' />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="actions" perPage={3} sortable={false} >
                    <SingleFieldList>
                        <ChipField source='path' />
                    </SingleFieldList>
                </ArrayField>
            </DatagridConfigurable>
        </List>
    );
}

export default FitmentRuleList;
